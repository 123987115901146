/* shantell-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Shantell Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../static/fonts/shantell-sans-v9-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* shantell-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Shantell Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/shantell-sans-v9-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@import './colors';


@mixin general-font-settings {
   font-size: 120%;
   color: $cmw-black;
}

@mixin playful-font {
   font-family: "Shantell Sans", cursive;
   font-weight: 600;
   @include general-font-settings;
}

@mixin readable-font {
   font-family: system-ui, sans-serif;
   @include general-font-settings;
}
