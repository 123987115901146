@import '../styles';

$width: 280px;

@mixin thumbnail ($borderImage, $thumnail-ratio) {

   @include playful-font;
   background-color: $cmw-verylightgreen;
   width: $width;

   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;

   color: $cmw-green;  

   .border {
      @include border($borderImage);
   }

   img {
      height: $width / $thumnail-ratio;
      width: $width;
      padding: 10% 5% 1%;
      object-fit: cover;
   }
}

@mixin border ($borderImage) {
   position: relative;
   &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: $borderImage;
   }
}


.horizontal1 {
   @include thumbnail(url(../static/elements/borders/border-1.png), 1.6)
}
.horizontal2 {
   @include thumbnail(url(../static/elements/borders/border-2.png), 1.6)
}
.horizontal3 {
   @include thumbnail(url(../static/elements/borders/border-3.png), 1.6)
}
.vertical1 {
   @include thumbnail(url(../static/elements/borders/border-vertical-1.png), 0.76)
}
.vertical2 {
   @include thumbnail(url(../static/elements/borders/border-vertical-2.png), 0.76)
}
.vertical3 {
   @include thumbnail(url(../static/elements/borders/border-vertical-3.png), 0.76)
}

.container {
   > h1, p {
      text-align: center;
   }
   > div {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax($width, 1fr));
      grid-auto-rows: 1fr;
      justify-items: center;
   }
   
   @include block;
   background-color: $cmw-verylightgreen;
}