@mixin divider {
   position: relative;
   &::after {
      z-index: 99;
      content: '';
      position: absolute;
      width: 100%;
      height: 11px;
      bottom: -4px;
      left: 0;
      background-image: url(../static/elements/general/divider.png);
      background-position: left bottom;
      background-size: cover 100%;
      background-repeat: no-repeat;
      @media (min-width: 1000px) {
         background-size: 100% 100%;
      }
   }
}

@mixin block {
   @include divider;
   width: 100%;
   padding: 50px 5% 50px;
   background-color: white;
}

