@import '../styles/';


.baseheader {
   background-image: url(../static/elements/general/header-background.png);
   background-position: right;
   background-size: cover;
   height: calc(min(600px, 100vw));
   width: 100%;
   position: relative;
}

.logo {
   z-index: 9;
   width: calc(min(40vw, 250px));
   height: calc(min(40vw, 250px));
   object-fit: contain;
   position: absolute;
   top: 0;
   right: 30px;
}

.sectionImage {
   position: absolute;
   width: 60%;
   height: 60%;
   object-fit: contain;
   bottom: 5%;
   left: 0;
}

.videoframe {
   position: absolute;
   overflow: hidden;
   bottom: 0;
   left: 0;
   width: calc(min(100%, 600px));
   padding-top: calc(min(100%, 600px));

   .thumbnail {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 22% 10% 12% 8%;
      height: calc(min(80%, 80vw));
      width: 100%;
      transform: rotate(-2deg);
      img {
         height: 100%;
         width: 100%;
         object-fit: cover;
      }
      
   } 
   button {
      position: absolute;
      top: 60%;
      cursor: pointer;
      left: 45%;
      z-index: 9999;      
      height: calc(min(8vw, 60px));
      width: calc(min(8vw, 60px));
      background-color: $cmw-verylightgreen;
      border: 3px solid $cmw-lightgreen;
      background-image: url(../static/elements/buttons/next.png);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 55% 50%;
      border-radius: 50%;
      @keyframes pulsate {
         from {transform: scale(1.3);}
         to {transform: scale(1.5);}
       }
       animation-name: pulsate;
       animation-timing-function: ease-in-out;
       animation-duration: 1s;
       animation-iteration-count: infinite;
       animation-direction: alternate;
   }

   &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: calc(min(80%, 80vw));
      width: 100%;
      z-index: 9;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../static/elements/header/header-frame.png);
   }
}

.video {
   width: 100%;
   height: calc(min(63vw, 630px));
}