@import '../styles';

.CategorySwitch {
   @include playful-font;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   > * {
      margin: 10px;
   }
   .active {
      color: $cmw-blue;
      position: relative;

      &::after {
         position: absolute;
         bottom: -2px;
         left: 0;
         content: '';
         width: 100%;
         height: 4px;
         background: $cmw-blue;
         border-radius: 2px;
      }
   }
   margin-bottom: 20px;
}