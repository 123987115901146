@import '../styles';

.about {
   @include block;

   h1 {
      margin-bottom: 10px;
   }

   .partners {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat( auto-fit, minmax(280px, 1fr));
      flex-direction: column;
      align-items: stretch;
   }
}

.partnerTile {
   display: flex;
   flex-direction: column;
   align-items: stretch;

   img {
      width: 100%;
   }

   > h3 {
      @include readable-font;
      margin: 10px 0;
      font-weight: 300;
   }

   > p {
      margin: 0;
   }

   border: 1px solid lightgrey;
   padding: 25px;
}

