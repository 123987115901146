@import './typography';

* {
   box-sizing: border-box;
}

body {
   margin: 0;
   @include readable-font;
}

h1, h2, h3 {
   @include playful-font;
   color: $cmw-blue;
}

h1 {
   font-size: 200%;
   margin: 0 0 0.6em;
}

h2 {
   font-size: 150%;
   margin: 1.2em 0 0.6em;
}

h3 {
   font-size: 120%;
   margin: 1.2em 0 0.6em;
}

p {
   margin: 0 0 1em;
}

a {
   text-decoration: none;
   color: $cmw-green;

   &:hover {
      color: $cmw-blue;
   }    
}

button {
   outline: none;
}