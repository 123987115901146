@import '../styles';

.list {
   @include block;

   display: flex;
   flex-direction: column;
   align-items: center;

   h1 {
      text-align: center;
   }

   > a {
      display: block;
      color: $cmw-darkgreen;
      padding: 10px 0;
      border-bottom: 1px dotted lightgreen;
      text-align: center; 
      
      &[data-first-occurance="true"] {
         &:not(:first-of-type) {
            margin-top: 50px;
         }
         
         &::first-letter {
            color: $cmw-red;
            font-weight: bold;
         }
      }
   }
}


