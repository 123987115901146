@import '../styles';

.page {
   @include block;
   padding-top: 70px;

   .content {
      max-width: 550px;
   }

   @media (min-width: 800px) {
      display: flex;
      justify-content: space-between;

      .buttons {
         width: 250px;
      }
   }
}

.prevnext {
   position: absolute;
   top: 0;
   left: 0;
   width: inherit;
   display: flex;
   justify-content: space-between;
   :last-child {
      margin-left: auto;
   }
   > button {
      margin: 2%;
      max-width: 45%;
      font-size: 110%;
      cursor: pointer;
      display: flex; 
      align-items: center;
      border: none;
      background: none;
      color: $cmw-lightgreen;
      :first-child {
         margin-right: 0.2em;
      }
      img {
         height: 16px;
         width: 16px;
         object-fit: contain;
      }
   }
   @media (max-width: 500px) {
      font-size: 90%;
   }
}