@import '../styles';


.button {
   @include playful-font;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 144px;

   font-size: 140%;
   color: $cmw-green;

   &:hover {
      color: $cmw-blue;
   }

   img {
      height: 116px;
      width: 144px;
      object-fit: contain;
   }
   span {
      margin-top: 5px;
      text-align: center;
   }
}

.buttonblock {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
   justify-items: center;
   > * {
      margin: 10px;
   }
}

.block {
   @include block;
}