@import '../styles';

@mixin menuItem ($image, $text) {
   background-image: $image;
   @media (min-width: 1000px) {
      all: unset;
      cursor: pointer;
      background: none;
      &::after {
         @include playful-font;
         color: $cmw-purple;
         content: $text;
      }
   }
}

.navbar {
   grid-area: navbar;
   display: flex; 
   justify-content: space-around;
   align-items: center;
   height: 60px;
   background-color: $cmw-purple;
   @include divider;
   @include playful-font;
   font-size: 120%;
   text-transform: uppercase;
   > * {
      height: 50px;
      width: 60px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;  
   }
   .home {
      @include menuItem(
         url(../static/elements/buttons/btn-alle-lieder.png), 
         'Home'
      );
   }
   .songs {
      @include menuItem(
         url(../static/elements/buttons/btn-noten.png), 
         'Liedertexte'
      );
   }
   .videos {
      @include menuItem(
         url(../static/elements/buttons/btn-videos.png), 
         'Videos'
      );
   }
   .rhymes {
      @include menuItem(
         url(../static/elements/buttons/btn-reime.png), 
         'Reime & Verse'
      );
   }
   .coloring {
      @include menuItem(
         url(../static/elements/buttons/btn-malvorlagen.png), 
         'Malvorlagen'
      );
   }
   @media (min-width: 1000px) {
      height: 85px;
      background-color: white;
   }
}

@mixin background {
   background-image: url(../static/elements/general/background.png);
   background-size: 50%;
}

.layout {
   display: grid;
   grid-template-rows: auto 1fr;
   grid-template-areas: 'navbar' 'content';
   width: 100%;
   height: 100vh;

   @include background;

   .adcol {
      display: none;
   }

   .content {
      grid-area: content;
      overflow: hidden scroll; // scroll -y
   }

   @media (min-width: 1000px) {
      grid-template-columns: 1fr minmax(700px, 1000px) 300px 1fr;
      grid-template-areas: '. navbar adcol .' '. content adcol .';

      .adcol {
         display: block;
         grid-area: adcol;
      }
   }
}



.footer {
   padding: 20px;
   height: 100px;
   background-color: $cmw-red;
   > a {
      font-size: 90%;
      color: white;
      &:hover {
         text-decoration: underline;
      }
   }

   display: flex;
   align-items: center;
   justify-content: flex-end;
   > * {
      margin: 10px;
   }
   &>:first-child {
      height: 23px;
      width: 200px;
      margin: 20px 10px;
      background-image: url(../static/elements/general/swissmom-logo-white.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: auto;
   }

   @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
   }
}




